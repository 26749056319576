import {
  AutoResizeTextArea,
  ContentWrapper,
  EditableSelect,
  Heading4,
  LinkButton,
  PrimaryButton,
  SettingsCard,
  SingleSelect,
  Switch,
  TrashIcon,
} from '@stellar-lms-frontend/ui-components';

import { useEffect, useState } from 'react';
import { ConfirmationModal } from '../../../confirmation-modal/confirmation-modal';
import { useTranslation } from 'react-i18next';
import { deepEqual } from '@stellar-lms-frontend/common-utils';

export type LevelForm = {
  id?: string;
  context: string;
  answers: {
    id?: string;
    text: string;
    feedback: string;
    correct: boolean;
  }[];
  spacedRepetitionEnabled: boolean;
};

export type ScenarioLevelEditProps = {
  levelNumber: number;
  value: LevelForm;
  onChange: (data: LevelForm) => void;
  onDelete: () => void;
};

export const ScenarioLevelEdit: React.FC<ScenarioLevelEditProps> = ({
  levelNumber,
  value,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'scenario-step-view' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [isOpen, setIsOpen] = useState(false);
  const [context, setContext] = useState<LevelForm['context']>(value.context);
  const [answers, setAnswers] = useState<LevelForm['answers']>(value.answers);
  const [spacedRepetitionEnabled, setSpacedRepetitionEnabled] = useState(
    value.spacedRepetitionEnabled,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (
      context !== value.context ||
      !deepEqual(answers, value.answers) ||
      spacedRepetitionEnabled !== value.spacedRepetitionEnabled
    ) {
      onChange({ context, answers, id: value.id, spacedRepetitionEnabled });
    }
  }, [
    answers,
    context,
    onChange,
    spacedRepetitionEnabled,
    value.answers,
    value.context,
    value.id,
    value.spacedRepetitionEnabled,
  ]);

  return (
    <>
      <ContentWrapper className="font-lexend mx-auto space-y-4 pb-6 pt-4">
        <div className="flex justify-between">
          <Heading4 className="text-text-01">{t('level.title', { count: levelNumber })}</Heading4>
          <button
            className="h-fit"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <TrashIcon className="text-text-02 h-6 w-6" />
          </button>
        </div>
        <AutoResizeTextArea
          value={context}
          onChange={(e) => setContext(e.target.value)}
          minRows={2}
          maxRows={5}
          label={t('context.label')}
          required
        />
        <SettingsCard
          onToggleOpenState={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          openChildren={
            <div className="space-y-4">
              <p className="type-body-medium">{t('card.title')}</p>
              <div className="-mr-10">
                <EditableSelect
                  options={value.answers}
                  onChange={(answers) =>
                    setAnswers(
                      answers.map((a) => ({
                        text: a.text,
                        feedback: a.feedback ?? '',
                        correct: a.correct ?? false,
                      })),
                    )
                  }
                  ctaLabel={t('card.open.cta-label')}
                  placeholder={t('card.open.placeholder')}
                  feedbackPlaceholder={t('card.open.feedback-placeholder')}
                  emptyOptionLabel={t('card.open.empty-option-label')}
                  showCorrectAnswerSelectors
                  isMultiSelect={false}
                  hasAnswerFeedback
                />
              </div>
              <div className="flex items-center space-x-4">
                <Switch
                  label={t('spaced-repetition-enabled')}
                  value={spacedRepetitionEnabled}
                  onChange={(checked) => setSpacedRepetitionEnabled(checked)}
                />
              </div>
            </div>
          }
          closedChildren={
            <div className="space-y-4">
              <p className="type-body-medium">{t('card.title')}</p>
              <div className="-mr-10">
                <SingleSelect
                  options={value.answers.map((a) => ({
                    label: a.text,
                    correct: a.correct,
                    id: a.id ?? '',
                    other: false,
                  }))}
                  showCorrectAnswerSelectors
                  isDisabled
                />
              </div>
            </div>
          }
        />
      </ContentWrapper>
      <ConfirmationModal
        title={t('delete-modal.title')}
        description={t('delete-modal.description')}
        buttons={{
          hasShadow: false,
          buttons: [
            <PrimaryButton
              key={1}
              theme="red"
              label={tGeneral('delete')}
              onClick={() => {
                onDelete();
                setIsDeleteModalOpen(false);
              }}
            />,
            <LinkButton
              buttonStyle="fit"
              key={2}
              onClick={() => setIsDeleteModalOpen(false)}
              label={tGeneral('cancel')}
            />,
          ],
        }}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};
