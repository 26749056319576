import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { regexConfig, SupportedServices } from '../embed/embed-utils';
import { PrimaryButton } from '../../../buttons';
import { Input } from '../../../form';

export const EmbedLoaderComponent = (props: NodeViewProps) => {
  const embedType = props.node.attrs['embedType'];
  const errorLabel = props.node.attrs['errorLabel'] ?? '';
  const addButtonLabel = props.node.attrs['addButtonLabel'] ?? '';
  const placeholderLabel = props.node.attrs['placeholderLabel'] ?? '';

  const [url, setUrl] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const validPattern = useMemo(() => getEmbedRegex(embedType), [embedType]);

  const onAddEmbed = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      if (!event) {
        return;
      }

      event.preventDefault();

      if (url) {
        const matches = [...url.matchAll(new RegExp(validPattern, 'g'))];
        if (matches && matches.length > 0) {
          props.editor
            .chain()
            .insertEmbed(props.getPos(), props.node.nodeSize, matches[0], embedType)
            .focus();
        } else {
          setError(errorLabel);
        }
      }
    },
    [embedType, errorLabel, props, url, validPattern],
  );

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }, []);

  return (
    <NodeViewWrapper>
      <div
        className="p-0 m-0"
        contentEditable={false}
        data-drag-handle
      >
        <div className="flex w-full gap-2 bg-surface-02 p-2 rounded-lg ">
          <div className="grow">
            <Input
              onChange={onInputChange}
              className="grow"
              placeholder={placeholderLabel}
              htmlId={'add-embed-to-editor'}
            />
            <span className="text-negative-01">{error}</span>
          </div>
          <PrimaryButton
            className="shrink-0"
            onClick={onAddEmbed}
            label={addButtonLabel}
          ></PrimaryButton>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

function getEmbedRegex(embedType: SupportedServices): RegExp {
  return regexConfig[embedType].regex;
}
