import * as showdown from 'showdown';
import {
  BlockContentItem,
  BlockContentType,
  EditorVersion,
} from '@stellar-lms-frontend/lms-graphql';
import { Extensions, generateJSON, JSONContent } from '@tiptap/core';
import { SupportedServices } from '../extensions/embed';
import { BlockEditorTypesEnum } from '../../block-editor/block-editor';

type I18N = {
  defaultWarningTitle: string;
  defaultExampleTitle: string;
  defaultKeyTakeawaysTitle: string;
  defaultPracticalTipsTitle: string;
  defaultDiscussionPromptTitle: string;
};

export function transformDatabaseBlocks(
  dbBlocks: (BlockContentItem | null)[],
  i18n: I18N,
  tiptapExtensions: Extensions,
): JSONContent {
  const transformedBlocks: JSONContent[] = dbBlocks
    .map((block) => {
      if (block) {
        const data = JSON.parse(block?.content ?? '');
        const editorJsType = data.editorJsType;

        if (block.editorVersion === EditorVersion.Editorjs || editorJsType) {
          return transformEditorJsBlock(block, i18n, tiptapExtensions);
        } else {
          return [data];
        }
      } else {
        return [];
      }
    })
    .flat();

  return {
    type: 'doc',
    content: transformedBlocks,
  };
}

function editorjsLevelToTipTap(levelType: string): 1 | 2 | 3 | 4 {
  switch (levelType) {
    case 'h1':
      return 1;
    case 'h2':
      return 2;
    case 'h3':
      return 3;
    case 'h4':
      return 4;
    default:
      return 1;
  }
}

function getEmbedType(service: string): SupportedServices {
  switch (service) {
    case 'youtube':
      return 'youtube';
    case 'vimeo':
      return 'vimeo';
    case 'genially':
      return 'genially';
    case 'synthesia':
      return 'synthesia';
    case 'nepazing':
      return 'nepazing';
    case 'outgrow':
      return 'outgrow';
    case 'hubspot':
      return 'hubspot';
    case 'imgur':
      return 'imgur';
    case 'gfycat':
      return 'gfycat';
    case 'miro':
      return 'miro';
  }

  return 'youtube';
}

function getDiscussionPromptBlocks(
  data: { title: string; text: string },
  blockId: string,
  defaultTitle: string,
) {
  return [
    {
      type: BlockEditorTypesEnum.DISCUSSION_PROMPT,
      attrs: {
        id: blockId,
      },
      content: [
        {
          type: 'heading',
          attrs: { level: 3 },
          content: [{ type: 'text', text: data.title ?? defaultTitle }],
        },
        {
          type: 'paragraph',
          content: [{ type: 'text', text: data.text }],
        },
      ],
    },
  ];
}

function textToParagraphContent(text: string, tiptapExtensions: Extensions) {
  const jsondoc = generateJSON(text, tiptapExtensions);
  if (jsondoc['content']?.[0]) {
    return jsondoc['content'][0].content;
  }

  return undefined;
}

function transformEditorJsBlock(block: BlockContentItem, i18n: I18N, extensions: Extensions) {
  let blocks: JSONContent[] = [];
  const data = JSON.parse(block?.content ?? '');
  const editorJsType = data.editorJsType;

  switch (block?.type) {
    case BlockContentType.Paragraph: {
      // hack to deal with misconfigured data
      if (editorJsType === 'discussion_prompt') {
        blocks = getDiscussionPromptBlocks(data, block.id, i18n.defaultDiscussionPromptTitle);
      } else if (data.text.length > 0) {
        const paragraphContent = textToParagraphContent(data.text, extensions);
        const newBlock: JSONContent = {
          type: 'paragraph',
          attrs: {
            id: block.id,
            textAlign: data.alignment ? data.alignment.toLowerCase() : 'left',
          },
        };
        if (paragraphContent) {
          newBlock.content = paragraphContent;
        }
        blocks = [newBlock];
      }
      break;
    }
    case BlockContentType.Header: {
      blocks = [
        {
          type: 'heading',
          attrs: {
            id: block.id,
            level: editorjsLevelToTipTap(editorJsType),
            textAlign: data.alignment.toLowerCase(),
          },
          content: textToParagraphContent(data.text, extensions),
        },
      ];
      break;
    }
    case BlockContentType.List: {
      const localBlock: JSONContent = {
        type: 'bulletList',
        attrs: {
          id: block.id,
        },
      };

      const style = data.style;
      if (style === 'ordered') {
        localBlock.type = 'orderedList';
      }

      const items = data.items.map((item: string) => ({
        type: 'listItem',
        content: [{ type: 'paragraph', content: [{ type: 'text', text: item }] }],
      }));

      localBlock.content = items;
      blocks = [localBlock];
      break;
    }
    case BlockContentType.Checklist: {
      blocks = [
        {
          type: 'taskList',
          attrs: {
            id: block.id,
          },
          content: data.items.map((item: { text: string; checked: boolean }) => ({
            type: 'taskItem',
            attr: {
              checked: item.checked,
            },
            content: [{ type: 'paragraph', content: [{ type: 'text', text: item.text }] }],
          })),
        },
      ];
      break;
    }
    case BlockContentType.Example: {
      blocks = [
        {
          type: 'example',
          attrs: {
            id: block.id,
          },
          content: [
            {
              type: 'heading',
              attrs: { level: 3 },
              content: [{ type: 'text', text: data.title ?? i18n.defaultExampleTitle }],
            },
            { type: 'paragraph', content: [{ type: 'text', text: data.text }] },
          ],
        },
      ];
      break;
    }
    case BlockContentType.Table: {
      if (data.content.length > 0) {
        let startIndex = 0;
        const content = [];
        if (data.withHeadings) {
          startIndex = 1;
          content.push({
            type: 'tableRow',
            content: data.content[0].map((cell: string) => ({
              type: 'tableHeader',
              content: [{ type: 'paragraph', content: [{ type: 'text', text: cell }] }],
            })),
          });
        }

        content.push(
          ...data.content.slice(startIndex).map((row: string[]) => ({
            type: 'tableRow',
            content: row.map((cell: string) => ({
              type: 'tableCell',
              content: [{ type: 'paragraph', content: [{ type: 'text', text: cell }] }],
            })),
          })),
        );

        blocks = [
          {
            type: 'table',
            attrs: {
              id: block.id,
            },
            content: content,
          },
        ];
      }
      break;
    }
    case BlockContentType.Warning: {
      blocks = [
        {
          type: BlockEditorTypesEnum.WARNING,
          attrs: {
            id: block.id,
          },
          content: [
            {
              type: 'heading',
              attrs: { level: 3 },
              content: [{ type: 'text', text: data.title ?? i18n.defaultWarningTitle }],
            },
            { type: 'paragraph', content: [{ type: 'text', text: data.text }] },
          ],
        },
      ];
      break;
    }
    case BlockContentType.DiscussionPrompt: {
      blocks = getDiscussionPromptBlocks(data, block.id, i18n.defaultDiscussionPromptTitle);
      break;
    }
    case BlockContentType.KeyTakeaways: {
      blocks = [
        {
          type: BlockEditorTypesEnum.KEY_TAKEAWAYS,
          attrs: {
            id: block.id,
          },
          content: [
            {
              type: 'heading',
              attrs: { level: 3 },
              content: [{ type: 'text', text: data.title ?? i18n.defaultKeyTakeawaysTitle }],
            },
            {
              type: 'bulletList',
              content: data.items.map((item: string) => ({
                type: 'listItem',
                content: [
                  {
                    type: 'paragraph',
                    content: item ? [{ type: 'text', text: item }] : [],
                  },
                ],
              })),
            },
          ],
        },
      ];
      break;
    }
    case BlockContentType.Code: {
      blocks = [
        {
          type: 'codeBlock',
          attrs: {
            id: block.id,
          },
          content: [{ type: 'text', text: data.code }],
        },
      ];
      break;
    }
    case BlockContentType.Divider: {
      blocks = [{ type: 'horizontalRule', attrs: { id: block.id } }];
      break;
    }
    case BlockContentType.PracticalTips: {
      blocks = [
        {
          type: BlockEditorTypesEnum.PRACTICAL_TIPS,
          attrs: {
            id: block.id,
          },
          content: [
            {
              type: 'heading',
              attrs: { level: 3 },
              content: [{ type: 'text', text: data.title ?? i18n.defaultPracticalTipsTitle }],
            },
            {
              type: 'orderedList',
              content: data.items.map((item: string) => ({
                type: 'listItem',
                content: [
                  { type: 'paragraph', content: item ? [{ type: 'text', text: item }] : [] },
                ],
              })),
            },
          ],
        },
      ];
      break;
    }
    case BlockContentType.Embed: {
      const embedType = getEmbedType(data.service);
      const caption = data.caption;
      const source = data.source;
      blocks = [
        {
          type: BlockEditorTypesEnum.EMBED,
          attrs: {
            id: block.id,
            src: source,
            embedType: embedType,
          },
          content: [{ type: 'paragraph', content: textToParagraphContent(caption, extensions) }],
        },
      ];
      break;
    }
    case BlockContentType.Image: {
      const fileId = data.file.id;
      const fileUrl = data.file.url;

      blocks = [
        {
          type: 'image',
          attrs: {
            id: block.id,
            src: fileUrl,
            fileId: fileId,
          },
        },
      ];
      break;
    }
    case BlockContentType.Article: {
      const title = data.title;
      const description = data.description;
      const iconUrl = data.iconUrl;
      const source = data.source;
      const link = data.link;

      blocks = [
        {
          type: BlockEditorTypesEnum.ARTICLE,
          attrs: {
            id: block.id,
            link,
            source,
            faviconUrl: iconUrl,
            title,
            description,
          },
        },
      ];
      break;
    }
    case BlockContentType.Document: {
      const fileId = data.file.id;
      const src = data.file.url;
      const fileName = data.filename;
      const mimeType = data.mimeType;

      blocks = [
        {
          type: BlockEditorTypesEnum.DOCUMENT_BLOCK,
          attrs: {
            id: block.id,
            fileName,
            src,
            mimeType,
            fileId,
          },
        },
      ];
      break;
    }
    case BlockContentType.Markdown: {
      const mdContent = data.text;
      blocks = convertMarkdownToBlocks(mdContent, extensions);
      break;
    }
  }
  return blocks;
}

function convertMarkdownToBlocks(markdown: string, extensions: Extensions): JSONContent[] {
  const sanitized = markdown
    .replaceAll('```', '') // Remove any code blocks
    // .replaceAll('\n\n', '\n')
    .replaceAll(/^(\s{2})([^\s])/g, '$1  $2') // 2 space indents to 4
    .replaceAll(/^(\s{4})([^\s])/g, '$1    $2') // 4 space indents to 8
    .replaceAll(/^(\s{3})([^\s])/g, '$1 $2'); // fix bad 3 space indents -.-
  const converter = new showdown.Converter();
  const html = converter.makeHtml(sanitized);
  const json = generateJSON(html, extensions);

  // todo if multiple ordered/unordered lists with just one item, bring them together
  return json['content'];
}
