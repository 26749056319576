import {
  NumberIcon,
  CommentCountIcon,
  ListCard,
  AddCard,
  DragVerticalList,
  TrashIcon,
} from '@stellar-lms-frontend/ui-components';
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { LEARNING_ACTIVITY_STEP_CREATE_URI } from '../routes';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { LearningActivityContext } from '../context/learning-activity-context';
import { FloatingStepOptions, FloatingStepOptionsI18n } from './floating-step-options';
import {
  AiGenerationStatus,
  LearningActivityOutline,
  LearningActivityType,
} from '@stellar-lms-frontend/lms-graphql';
import Skeleton from 'react-loading-skeleton';

export type LearningActivityStepListI18N = {
  add?: string;
  floatingStepOptions?: FloatingStepOptionsI18n;
  delete?: string;
};

export type LearningActivityStepListActions = {
  learningActivity?: {
    generateHowTo: () => Promise<void>;
  };
  step?: {
    delete?: (learningActivityId: string, stepId: string) => void;
    updateOrder?: (variables: {
      courseId: string;
      moduleId: string;
      learningActivityId: string;
      stepIds: string[];
    }) => void;
    onChange?: (callback: VoidFunc, saveFunc?: () => Promise<void>) => void;
    onCreate?: (callback: VoidFunc, saveFunc?: () => Promise<void>) => void;
  };
};

export type LearningActivityStepListProps = {
  learningActivity?: LearningActivityOutline;
  navigateToLearningActivityStep: (stepId: string) => void;
  highlightedStepId?: string;
  discussionAllowed: boolean;
  isEditing: boolean;
  i18n?: LearningActivityStepListI18N;
  actions?: LearningActivityStepListActions;
  isCreating: boolean;
  moduleId?: string;
};

export const LearningActivityStepList: React.FC<LearningActivityStepListProps> = ({
  learningActivity,
  navigateToLearningActivityStep,
  highlightedStepId,
  discussionAllowed,
  isEditing,
  i18n,
  actions,
  moduleId,
}) => {
  const { courseId } = useParams();
  const [isSelectingAddType, setIsSelectingAddType] = useState(false);
  const { saveFunc, isDirty } = useContext(LearningActivityContext);

  const isExperimentLA = learningActivity?.type === LearningActivityType.Experiment;

  const editMode = useMemo(
    () => (
      <div className="mt-4 space-y-2">
        {learningActivity?.steps && (
          <DragVerticalList
            items={learningActivity?.steps}
            listClassName="space-y-2"
            onChange={(steps) =>
              courseId &&
              moduleId &&
              actions?.step?.updateOrder?.({
                courseId,
                moduleId,
                learningActivityId: learningActivity.id,
                stepIds: steps.map((s) => s.id),
              })
            }
          >
            {({ item: step, listeners, setActivatorNodeRef }) => {
              return step.aiGenerationStatus &&
                step.aiGenerationStatus === AiGenerationStatus.Ongoing ? (
                <ListCard
                  id={step.id}
                  isHighlighted={step.id === highlightedStepId}
                >
                  <Skeleton />
                </ListCard>
              ) : (
                <ListCard
                  id={step.id}
                  isHighlighted={step.id === highlightedStepId}
                  label={step.title ?? ''}
                  onClick={() =>
                    isDirty
                      ? actions?.step?.onChange?.(
                          () => navigateToLearningActivityStep(step.id),
                          saveFunc,
                        )
                      : navigateToLearningActivityStep(step.id)
                  }
                  dragProps={{
                    listeners,
                    setActivatorNodeRef,
                  }}
                  options={[
                    {
                      label: i18n?.delete,
                      onClick: () => {
                        actions?.step?.delete?.(learningActivity?.id, step.id);
                      },
                      left: <TrashIcon className="text-negative-01 h-4 w-4" />,
                      className: 'text-negative-01',
                    },
                  ]}
                />
              );
            }}
          </DragVerticalList>
        )}
        <FloatingStepOptions
          placement="right-end"
          showHowToButton={isExperimentLA}
          howToCreateAction={actions?.learningActivity?.generateHowTo ?? (() => Promise.resolve())}
          wrappedComponent={
            <div>
              <AddCard
                isEnabled
                onClick={() =>
                  isDirty
                    ? actions?.step?.onCreate?.(
                        () => setIsSelectingAddType((prev) => !prev),
                        saveFunc,
                      )
                    : setIsSelectingAddType((prev) => !prev)
                }
                label={i18n?.add ?? ''}
              />
            </div>
          }
          isOpen={isSelectingAddType}
          onClose={() => setIsSelectingAddType(false)}
          stepCreateUrl={LEARNING_ACTIVITY_STEP_CREATE_URI}
          i18n={i18n?.floatingStepOptions}
        />
      </div>
    ),
    [
      actions?.step,
      courseId,
      highlightedStepId,
      i18n?.add,
      i18n?.delete,
      i18n?.floatingStepOptions,
      isDirty,
      isSelectingAddType,
      learningActivity?.id,
      learningActivity?.steps,
      moduleId,
      navigateToLearningActivityStep,
      saveFunc,
    ],
  );

  const readMode = useMemo(
    () => (
      <ul className="mt-4">
        {learningActivity?.steps?.map((step, index) => (
          <li
            key={step.id}
            className="font-lexend group"
            onClick={() => navigateToLearningActivityStep(step.id)}
          >
            <div className="group flex cursor-pointer items-start space-x-4 py-3">
              <NumberIcon
                index={index + 1}
                selected={step.id === highlightedStepId}
                completed={!!step.completed}
              />
              <div className="grow">
                <p
                  className={`text-base font-medium ${
                    step.id === highlightedStepId ? 'text-primary-01' : 'text-text-01'
                  } `}
                  data-test-id="step-title"
                >
                  {step.aiGenerationStatus &&
                  step.aiGenerationStatus === AiGenerationStatus.Ongoing ? (
                    <Skeleton />
                  ) : (
                    step.title
                  )}
                </p>
                {!!step.commentCount && discussionAllowed && (
                  <div className="text-text-02 mt-1 flex items-center gap-2 text-sm">
                    <CommentCountIcon />
                    <span>{step.commentCount}</span>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    ),
    [discussionAllowed, learningActivity?.steps, navigateToLearningActivityStep, highlightedStepId],
  );

  return isEditing ? editMode : readMode;
};
