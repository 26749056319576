import moment from 'moment';
import * as d3_scale from 'd3-scale';
import * as d3_time from 'd3-time';
import { Area, AreaChart, Brush, XAxis, YAxis } from 'recharts';
import { formatDate } from '@stellar-lms-frontend/common-utils';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '../shadcn';
import { useMemo } from 'react';
import { LoaderWrapper } from './loader-wrapper';

type RowType = {
  date: string;
  count: number;
}[];

export const TimeSeriesAreaChart = ({
  isLoading,
  rows,
  chartConfig,
}: {
  isLoading: boolean;
  rows: RowType | undefined;
  chartConfig: ChartConfig;
}) => {
  const ticks = useMemo(() => {
    if (rows) {
      return getTicks(rows);
    } else {
      return [];
    }
  }, [rows]);

  const transformedData = useMemo(() => {
    if (rows) {
      return getTicksData(rows, ticks);
    } else {
      return [];
    }
  }, [rows, ticks]);

  return (
    <LoaderWrapper
      isLoading={isLoading}
      hasValue={rows !== undefined && rows.length > 0}
    >
      <ChartContainer
        className="min-h-[100%]"
        style={{ aspectRatio: 'unset !important' }}
        config={chartConfig}
      >
        <AreaChart
          accessibilityLayer
          data={transformedData}
          height={200}
          margin={{
            top: 50,
            bottom: 50,
            right: 50,
          }}
        >
          <XAxis
            dataKey="date"
            ticks={ticks}
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickCount={ticks.length}
            tickFormatter={(value) => formatDate(moment(value))}
          />
          <YAxis
            allowDecimals={false}
            domain={[0, 'dataMax']}
          />
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Area
            dataKey="count"
            type="linear"
            stroke="var(--color-count)"
            fill="var(--color-count)"
            strokeWidth={2}
            dot={false}
          />
          <Brush
            tickFormatter={xAxisTickFormatter}
            dataKey="date"
          />
        </AreaChart>
      </ChartContainer>
    </LoaderWrapper>
  );
};

const getTicks = (rows: { date: string }[]) => {
  if (!rows || !rows.length) {
    return [];
  }

  const domain = [new Date(rows[0].date), new Date(rows[rows.length - 1].date)];
  const scale = d3_scale.scaleTime().domain(domain).range([0, 1]);
  const ticks = scale.ticks(d3_time.timeDay);

  return ticks.map((entry) => +entry);
};

const getTicksData = (rows: { date: string; count: number }[], ticks: number[]) => {
  if (!rows || !rows.length) {
    return [];
  }

  const transformedData = rows.map((i) => ({ date: new Date(i.date).getTime(), count: i.count }));

  const dataMap = new Map(rows.map((i) => [new Date(i.date).getTime(), i]));
  ticks.forEach((item) => {
    if (!dataMap.has(item)) {
      transformedData.push({ date: item, count: 0 });
    }
  });

  transformedData.sort((a, b) => a.date - b.date);
  return transformedData.map((i) => ({ count: i.count, date: formatDate(moment(i.date)) }));
};

const xAxisTickFormatter = (timestamp_measured: string) => {
  return formatDate(moment(timestamp_measured));
};
