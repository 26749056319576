import DragHandle from '@tiptap-pro/extension-drag-handle-react';
import { Editor } from '@tiptap/react';

import useContentItemActions from './hooks/useContentItemActions';
import { useData } from './hooks/useData';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { Toolbar } from '../../ui/Toolbar';
import { DropdownButton } from '../../ui/Dropdown';
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';
import { faArrowDown, faArrowUp, faPlusCircle, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Surface } from '../../ui/Surface';
import { useTextmenuCommands } from '../TextMenu/hooks/useTextmenuCommands';
import { AIOptions } from './ai-options';

export type ContentItemMenuProps = {
  editor: Editor;
};

const supportsAIFunctions = ['paragraph'];

export const ContentItemMenu = ({ editor }: ContentItemMenuProps) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [menuOpen, setMenuOpen] = useState(false);
  const data = useData();
  const commands = useTextmenuCommands(editor);
  const actions = useContentItemActions(editor, data.currentNode, data.currentNodePos);

  useEffect(() => {
    if (menuOpen) {
      editor.commands.setMeta('lockDragHandle', true);
    } else {
      editor.commands.setMeta('lockDragHandle', false);
    }
  }, [editor, menuOpen]);

  const handleMenuOpen = useCallback(
    (open: boolean) => {
      setMenuOpen(open);
      const isSelectable = data.currentNode?.type.spec.selectable ?? false;
      if (open && isSelectable) {
        editor.chain().setNodeSelection(data.currentNodePos).run();
      }
    },
    [data.currentNodePos, data.currentNode, editor],
  );

  if (!editor.isEditable) {
    return null;
  }

  return (
    <DragHandle
      pluginKey="ContentItemMenu"
      editor={editor}
      onNodeChange={data.handleNodeChange}
      tippyOptions={{
        offset: [-2, 16],
        zIndex: 99,
      }}
    >
      <div className="flex items-center gap-1">
        <Toolbar.Button onClick={actions.handleAdd}>
          <FontAwesomeIcon
            icon={faPlusCircle}
            className="text-xl"
          />
        </Toolbar.Button>
        <Popover.Root
          open={menuOpen}
          onOpenChange={handleMenuOpen}
        >
          <Popover.Trigger asChild>
            <Toolbar.Button>
              <FontAwesomeIcon
                icon={faGripDotsVertical}
                className="text-xl"
              />
            </Toolbar.Button>
          </Popover.Trigger>
          <Popover.Content
            side="bottom"
            align="start"
            sideOffset={8}
          >
            <Surface className="p-2 flex flex-col gap-1 min-w-[16rem]">
              <div className="grid grid-cols-1 gap-1">
                <Popover.Close>
                  <DropdownButton onClick={actions.moveUp}>
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      fixedWidth
                      className="text-xl"
                    />
                    {tGeneral('move-up')}
                  </DropdownButton>
                  <DropdownButton
                    onClick={actions.deleteNode}
                    className="text-negative-01"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      fixedWidth
                      className="text-xl text-negative-01"
                    />
                    {tGeneral('delete')}
                  </DropdownButton>
                  <DropdownButton onClick={actions.moveDown}>
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      fixedWidth
                      className="text-xl"
                    />
                    {tGeneral('move-down')}
                  </DropdownButton>
                </Popover.Close>
                {supportsAIFunctions.includes(data.currentNode?.type.name ?? '') && (
                  <AIOptions
                    onFixSpelling={commands.onFixSpelling}
                    onMakeLonger={commands.onMakeLonger}
                    onMakeShorter={commands.onMakeShorter}
                    onSimplify={commands.onSimplify}
                    onTone={commands.onTone}
                  />
                )}
              </div>
            </Surface>
          </Popover.Content>
        </Popover.Root>
      </div>
    </DragHandle>
  );
};
