import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { DocumentUpload as DocumentUploadComponent } from './view/document-upload';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    documentUpload: {
      setDocumentUpload: (companyId: string) => ReturnType;
    };
  }
}

export const DocumentUpload = (documentsEnabled: boolean) =>
  Node.create({
    name: 'documentUpload',

    isolating: true,

    defining: true,

    group: 'block',

    draggable: true,

    selectable: false,

    inline: false,

    parseHTML() {
      return [
        {
          tag: `div[data-type="${this.name}"]`,
        },
      ];
    },

    renderHTML() {
      return ['div', { 'data-type': this.name }];
    },

    addAttributes() {
      return {
        companyId: {
          default: null,
        },
        documentsEnabled: {
          default: documentsEnabled,
        },
      };
    },

    addCommands() {
      return {
        setDocumentUpload:
          (companyId) =>
          ({ commands }) =>
            commands.insertContent(`<div data-type="${this.name}" companyId="${companyId}"></div>`),
      };
    },

    addNodeView() {
      return ReactNodeViewRenderer(DocumentUploadComponent);
    },
  });

export default DocumentUpload;
