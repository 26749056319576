import { Node } from '@tiptap/pm/model';
import { NodeSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import { useCallback } from 'react';

const useContentItemActions = (
  editor: Editor,
  currentNode: Node | null,
  currentNodePos: number,
) => {
  const resetTextFormatting = useCallback(() => {
    const chain = editor.chain();

    chain.setNodeSelection(currentNodePos).unsetAllMarks();

    if (currentNode?.type.name !== 'paragraph') {
      chain.setParagraph();
    }

    chain.run();
  }, [editor, currentNodePos, currentNode?.type.name]);

  const duplicateNode = useCallback(() => {
    if (currentNode?.type.spec.selectable) {
      editor.commands.setNodeSelection(currentNodePos);
    }

    const { $anchor } = editor.state.selection;
    const selectedNode = $anchor.node(1) || (editor.state.selection as NodeSelection).node;

    editor
      .chain()
      .setMeta('hideDragHandle', true)
      .insertContentAt(currentNodePos + (currentNode?.nodeSize || 0), selectedNode.toJSON())
      .run();
  }, [editor, currentNodePos, currentNode?.nodeSize, currentNode?.type]);

  const copyNodeToClipboard = useCallback(() => {
    editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).run();

    document.execCommand('copy');
  }, [editor, currentNodePos]);

  const deleteNode = useCallback(() => {
    editor
      .chain()
      .setMeta('hideDragHandle', true)
      .setNodeSelection(currentNodePos)
      .deleteSelection()
      .run();
  }, [editor, currentNodePos]);

  const moveUp = useCallback(() => {
    if (currentNode) {
      const resolvedPos = editor.view.state.doc.resolve(currentNodePos);

      const index = resolvedPos.index();

      if (index < 1) {
        return;
      }

      const posIndexBefore = resolvedPos.posAtIndex(index - 1);
      const resolvedBefore = editor.view.state.doc.resolve(posIndexBefore);
      const beforeNodePos = editor.$pos(posIndexBefore);

      const tr = editor.state.tr;
      const beforeNode = resolvedBefore.nodeAfter;
      if (beforeNode) {
        tr.replaceWith(currentNodePos, currentNodePos + currentNode.nodeSize, beforeNode);
        tr.replaceWith(
          beforeNodePos.pos,
          beforeNodePos.pos + resolvedBefore.nodeAfter.nodeSize,
          currentNode,
        );

        editor.view.dispatch(tr);
      }
    }
  }, [currentNode, currentNodePos, editor]);

  const moveDown = useCallback(() => {
    if (currentNode) {
      const resolvedPos = editor.view.state.doc.resolve(currentNodePos);

      const index = resolvedPos.index();
      const parent = resolvedPos.parent;

      if (index >= parent.childCount - 1) {
        return;
      }

      const posIndexAfter = resolvedPos.posAtIndex(index + 1);
      const resolvedAfter = editor.view.state.doc.resolve(posIndexAfter);
      const afterNodePos = editor.$pos(posIndexAfter);

      if (parent) {
        const tr = editor.state.tr;
        const afterNode = resolvedAfter.nodeAfter;
        if (afterNode) {
          tr.replaceWith(
            afterNodePos.pos,
            afterNodePos.pos + resolvedAfter.nodeAfter.nodeSize,
            currentNode,
          );
          tr.replaceWith(currentNodePos, currentNodePos + currentNode.nodeSize, afterNode);
          editor.view.dispatch(tr);
        }
      }
    }
  }, [currentNode, currentNodePos, editor]);

  const handleAdd = useCallback(() => {
    if (currentNodePos !== -1) {
      const currentNodeSize = currentNode?.nodeSize || 0;
      const insertPos = currentNodePos + currentNodeSize;
      const currentNodeIsEmptyParagraph =
        currentNode?.type.name === 'paragraph' && currentNode?.content?.size === 0;
      const focusPos = currentNodeIsEmptyParagraph ? currentNodePos + 2 : insertPos + 2;

      editor
        .chain()
        .command(({ dispatch, tr, state }) => {
          if (dispatch) {
            if (currentNodeIsEmptyParagraph) {
              tr.insertText('/', currentNodePos, currentNodePos + 1);
            } else {
              tr.insert(
                insertPos,
                state.schema.nodes['paragraph'].create(null, [state.schema.text('/')]),
              );
            }

            return dispatch(tr);
          }

          return true;
        })
        .focus(focusPos)
        .run();
    }
  }, [currentNode, currentNodePos, editor]);

  return {
    resetTextFormatting,
    duplicateNode,
    copyNodeToClipboard,
    deleteNode,
    handleAdd,
    moveUp,
    moveDown,
  };
};

export default useContentItemActions;
