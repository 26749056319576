import {
  ApplyIcon,
  BaseCard,
  ExperimentIcon,
  FloatingOptions,
  GuideIcon,
  MiscellaneousIcon,
  PlusCircleIcon,
  RetainIcon,
} from '@stellar-lms-frontend/ui-components';
import { LearningActivityListI18N } from '../../learning-activity-list';
import React, { useState } from 'react';
import { LearningActivityType } from '../../../utils/learningActivity';

export type CreateData = {
  title: string;
  description: string;
  durationInSeconds: number;
  openDate: string;
  mandatoryPrerequisiteLearningActivityIds: string[];
};

type CreateProps = {
  i18n: LearningActivityListI18N;
  onSelect: (type: LearningActivityType) => void;
};

const Create = React.forwardRef<HTMLDivElement, CreateProps>(({ i18n, onSelect }, ref) => {
  const [isSelectingLearningActivityType, setIsSelectingLearningActivityType] = useState(false);

  const options = [
    {
      label: i18n.learningActivityTypes?.guide.label,
      subtitle: i18n.learningActivityTypes?.guide.description,
      onClick: () => {
        onSelect(LearningActivityType.Guide);
      },
      left: GuideIcon,
    },
    {
      label: i18n.learningActivityTypes?.experiment.label,
      subtitle: i18n.learningActivityTypes?.experiment.description,
      onClick: () => {
        onSelect(LearningActivityType.Experiment);
      },
      left: ExperimentIcon,
    },
    {
      label: i18n.learningActivityTypes?.apply.label,
      subtitle: i18n.learningActivityTypes?.apply.description,
      onClick: () => {
        onSelect(LearningActivityType.Apply);
      },
      left: ApplyIcon,
    },
    {
      label: i18n.learningActivityTypes?.retain.label,
      subtitle: i18n.learningActivityTypes?.retain.description,
      onClick: () => {
        onSelect(LearningActivityType.Retain);
      },
      left: RetainIcon,
    },
    {
      label: i18n.learningActivityTypes?.miscellaneous.label,
      subtitle: i18n.learningActivityTypes?.miscellaneous.description,
      onClick: () => {
        onSelect(LearningActivityType.Unknown);
      },
      left: MiscellaneousIcon,
    },
  ];

  return (
    <FloatingOptions
      wrappedComponent={
        <BaseCard
          className="w-full"
          onClick={(e) => {
            e.stopPropagation();
            setIsSelectingLearningActivityType(true);
          }}
          childrenClassName={`flex cursor-pointer items-center px-12 py-5 w-full gap-2`}
          ref={ref}
        >
          <PlusCircleIcon className={`text-primary-02`} />
          <span className={`text-primary-02`}>{i18n.addNewLearningActivity}</span>
        </BaseCard>
      }
      isOpen={isSelectingLearningActivityType}
      onClose={() => setIsSelectingLearningActivityType(false)}
      placement={'left'}
      options={options.map(({ onClick, left: Left, ...rest }) => ({
        ...rest,
        onClick: () => {
          onClick();
          setIsSelectingLearningActivityType(false);
        },
        left: (
          <div className="h-12 w-12 flex items-center justify-center bg-surface-02 rounded-full ">
            <Left className="text-primary-02 h-8 w-8" />
          </div>
        ),
      }))}
      widthClassName={'w-[430px]'}
      heightClassName={'max-h-[470px]'}
    />
  );
});

export { Create };
